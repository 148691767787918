import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import useSWR, { Fetcher, SWRConfiguration } from 'swr';
import type { ResponseError } from '../lib/fetch';

type UseQueryResponse<T> = {
  isValidating: boolean;
  isLoading: boolean;
  data: Maybe<T>;
  error: ResponseError;
};

type QueryConfig = SWRConfiguration & {
  enabled?: boolean;
};

export function useQuery<T>(
  url: string | null,
  fetcher: Fetcher<T>,
  config?: QueryConfig
): UseQueryResponse<T> {
  const isEnabled = config?.enabled ?? true;
  const validURL = isEnabled ? url : null;

  const { error, data, isLoading, isValidating } = useSWR<T>(
    validURL,
    fetcher,
    {
      ...(config ?? {}),
      revalidateOnMount: config?.revalidateOnMount ?? true,
    }
  );

  return {
    error,
    data,
    isLoading,
    isValidating,
  };
}

const debouncedUrlAtom = atom<string>('');

export function useDebouncedQuery<T>(
  url: string,
  fetcher: Fetcher<T>,
  delay = 400,
  config?: SWRConfiguration
): UseQueryResponse<T> {
  const setDebouncedUrl = useSetAtom(debouncedUrlAtom);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedUrl(url), delay);
    return () => clearTimeout(timer);
  }, [url, delay]);

  const debouncedUrl = useAtomValue(debouncedUrlAtom);
  return useQuery<T>(debouncedUrl, fetcher, config);
}
