import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/navigation';
import { UrlPaths } from '../../lib/constants';
import { useMutation } from '../../hooks/useMutation';
import { APIFetch } from '../../lib/fetch';
import {
  AcceptTermsMutationPayload,
  SignInWithEmailPasswordMutationPayload,
  SignOutMutationPayload,
} from './types';
import useCsrfToken from '../../hooks/use-csrf-token-header';
import { getHostURL } from '../../lib/env';
import { useSWRConfig } from 'swr';
import { useClearMessages } from '../messages/hooks';
import HttpStatusCode from '../../lib/http-status-code.enum';
import { useUserSession } from '../user/hooks';

const CSRF_TOKEN_HEADER = 'x-csrf-token';

export function useSignOut() {
  const { t } = useTranslation();
  const router = useRouter();
  const csrfToken = useCsrfToken();
  const session = useUserSession();
  const clearMessages = useClearMessages();
  const { mutate: swrMutate } = useSWRConfig();

  const logoutUrl = '/api/auth/logout';

  const clearCache = async () => {
    fetch.authorize();
    clearMessages();
    session.set(null);
    await swrMutate(() => true, undefined, { revalidate: false });
  };

  const fetch = new APIFetch(getHostURL()).addHeaders({
    [CSRF_TOKEN_HEADER]: csrfToken,
  });

  const mutation = useMutation<SignOutMutationPayload, {}>(
    logoutUrl,
    fetch.post,
    {
      onSuccess: async () => {
        router.push(UrlPaths.SIGN_IN);
        await clearCache();
      },
      onError: (error) => {
        console.log(error);
        toast.error(t('auth:logOutError'));
      },
    }
  );

  const mutate = () => {
    mutation.mutate({});
  };

  return { mutate };
}

export function useSignInWithEmailPassword() {
  const { t } = useTranslation();
  const router = useRouter();
  const csrfToken = useCsrfToken();

  const loginUrl = '/api/auth/login';

  const fetch = new APIFetch(getHostURL()).addHeaders({
    [CSRF_TOKEN_HEADER]: csrfToken,
  });

  return useMutation<SignInWithEmailPasswordMutationPayload, {}>(
    loginUrl,
    fetch.post,
    {
      onSuccess: () => {
        router.push(UrlPaths.HOME);
      },
      onError: (error) => {
        const message =
          error.status === HttpStatusCode.Unauthorized
            ? t('auth:invalidCredentials')
            : t('auth:signInError');

        toast.error(error.info.message || message, {
          id: 'auth-error-message',
        });
      },
    }
  );
}

export function useAcceptTermsOfUse() {
  const csrfToken = useCsrfToken();
  const router = useRouter();
  const { t } = useTranslation();

  const acceptTermsOfUseUrl = '/api/onboarding/terms-of-use';

  const fetch = new APIFetch(getHostURL()).addHeaders({
    [CSRF_TOKEN_HEADER]: csrfToken,
  });

  return useMutation<AcceptTermsMutationPayload, {}>(
    acceptTermsOfUseUrl,
    fetch.post,
    {
      onSuccess: () => {
        router.push(UrlPaths.HOME);
      },
      onError: (error) => {
        toast.error(t('auth:acceptTermsAPIError'));
      },
    }
  );
}
