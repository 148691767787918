import useCsrfToken from '../../hooks/use-csrf-token-header';
import { APIFetch } from '../../lib/fetch';
import { getHostURL } from '../../lib/env';
import { useQuery } from '../../hooks/useQuery';

const CSRF_TOKEN_HEADER = 'x-csrf-token';

export function useDeleteStaleCookies() {
  const csrfToken = useCsrfToken();
  const url = '/api/auth/stale';

  const fetch = new APIFetch(getHostURL()).addHeaders({
    [CSRF_TOKEN_HEADER]: csrfToken,
  });

  useQuery(url, fetch.get, {
    onSuccess: async () => {
      console.log('Stale call success');
    },
    onError: (error) => {
      console.log('Stale call error: ', error);
    },
  });
}
